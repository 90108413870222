import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { formatRelative, parseISO } from 'date-fns';

import { MESES } from "src/utils/constantes";

export const addMonthsInDate = (dt, months, pattern = "yyyy-MM-dd") => {
    return format(new Date(new Date(dt).setMonth(new Date(dt).getMonth()+months)), pattern, {locale: ptBR});
}

export const beforeOrEqualToday = dt => {
    return compare(dt, getDataAtual()) < 1;
}

export const afterOrEqualToday = dt => {
    return compare(dt, getDataAtual()) >= 0;
}

// receber no formato YYYY-MM-DD
export const parseToDate = dateString => {
    return new Date(dateString.split('-')); // sem o .split('-'), criava a data com 1 dia a menos. 
}

export const age = dataNascimento => {
    if (!dataNascimento) {
        return "";
    }
    let today = new Date();
    let birthDate = parseToDate(dataNascimento);
    let age = today.getFullYear() - birthDate.getFullYear();
    let mesAtual = today.getMonth() + 1;
    let diaAtual = today.getDate();   // getDay é dia da semana; getDate é dia do mês
    let mesAniversario = birthDate.getMonth() + 1;
    let diaAniversario = birthDate.getDate();
    if (mesAtual < mesAniversario || (mesAtual === mesAniversario && diaAtual < diaAniversario)) {
        age--;
    }

    return age;
}

export const checkDate = value => {
    return !isNaN(Date.parse(value));
}

// Receber formato YYYY-MM-DD
export const compare = (data1, data2) => {
    if (data1 < data2) {
        return -1;
    } else if (data1 === data2) {
        return 0;
    } else {
        return 1;
    }
}

// Receber formato YYYY-MM-DD
export const compareMonth = (data1, data2) => {
    let d1 = data1.split('-');
    let d2 = data2.split('-');
    let m1 = d1[1];
    let m2 = d2[1];
    if (m1 < m2) {
        return -1;
    } else if (m1 === m2) {
        return 0;
    } else {
        return 1;
    }
}

// Receber formato YYYY-MM-DD
export const extractDay = dt => {
    let d1 = dt.split('-');
    return d1[2];
}

// Receber formato YYYY-MM-DD
export const extractYear = dt => {
    let d1 = dt.split('-');
    return d1[0];
}

export const formatarRelative = dt => {
    return dt 
        ? formatRelative( parseISO(dt), new Date(), {locale: ptBR})
        : "";
}

export const getAnoAtual = () => {
    let pattern = "yyyy";
    return format(new Date(), pattern, {locale: ptBR});
}

export const getDataAtual = formato => {
    let pattern = formato ? formato : "yyyy-MM-dd";
    return format(new Date(), pattern, {locale: ptBR});
}

export const getDayOfWeek = dateString => {
    const date = new Date(dateString.split('-'));
    const dayOfWeek = date.getDay();
    const daysOfWeek = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"];
    return daysOfWeek[dayOfWeek];
}

export const getMesAtual = formato => {
    let pattern = formato ? formato : "M";
    return format(new Date(), pattern, {locale: ptBR});
}

export const getNomeMes = mes => {
    return MESES.filter(m => parseInt(m.id) === parseInt(mes))[0]?.nome;
}

export const getPrimeiroDiaMesAtual = formato => {
    let pattern = formato ? formato : "yyyy-MM-dd";
    let date = new Date();
    let dt = new Date(date.getFullYear(), date.getMonth(), 1);
    return format(dt, pattern, {locale: ptBR});
}

export const getPrimeiroDiaMesAnterior = formato => {
    let mesAtual = getMesAtual(); // 1 = jan / 2 = fev
    let anoAtual = getAnoAtual();
    if (mesAtual > 1) {
        mesAtual = mesAtual - 1;
    } else {
        mesAtual = 12;
        anoAtual = anoAtual - 1;
    }
    let pattern = formato ? formato : "yyyy-MM-dd";
    let dt = new Date(anoAtual, mesAtual-1, 1); // mês: 0 = jan / 1 = fev
    return format(dt, pattern, {locale: ptBR});
}

export const getUltimoDiaMesAtual = formato => {
    let pattern = formato ? formato : "yyyy-MM-dd";
    let date = new Date();
    let dt = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return format(dt, pattern, {locale: ptBR});
}

export const getUltimoDiaMesAnterior = formato => {
    let mesAtual = getMesAtual(); // 1 = jan / 2 = fev
    let anoAtual = getAnoAtual();
    if (mesAtual > 1) {
        mesAtual = mesAtual - 1;
    } else {
        mesAtual = 12;
        anoAtual = anoAtual - 1;
    }    
    let pattern = formato ? formato : "yyyy-MM-dd";
    let dt = new Date(anoAtual, mesAtual, 0);
    return format(dt, pattern, {locale: ptBR});
}

export const isToday = dt => {
    return getDataAtual() === dt;
}

// Remember that the month is 0-based so February is actually 1...
export const isValidDate = (year, month, day) => {
    var d = new Date(year, month, day);
    if (d.getFullYear() === year && d.getMonth() === month && d.getDate() === day) {
        return true;
    }
    return false;
}

export const monthsAgo = (months, pattern = "yyyy-MM-dd") => {
    return format(new Date(new Date().setMonth(new Date().getMonth()-months)), pattern, {locale: ptBR});
}

export const subtractMonthsInDate = (dt, months, pattern = "yyyy-MM-dd") => {
    return format(new Date(new Date(dt).setMonth(new Date(dt).getMonth()-months)), pattern, {locale: ptBR});
}

export const yesterday = (pattern = "yyyy-MM-dd") => {
    let ontem = new Date();
    ontem.setDate(ontem.getDate()-1);
    return format(ontem, pattern, {locale: ptBR});
}

export const getPreviousWeekDay = (pattern = "yyyy-MM-dd") => {
    let ontem = new Date();
    ontem.setDate(ontem.getDate()-1);
    if (ontem.getDay() === 0) { // domingo
        ontem.setDate(ontem.getDate() - 2); // Subtrai mais 2 dias para chegar à sexta-feira
    } else if (ontem.getDay() === 6) { // sábado
        ontem.setDate(ontem.getDate() - 1); 
    }    
    return format(ontem, pattern, {locale: ptBR});
}