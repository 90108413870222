import { createContext } from 'react';

const StoreContext = createContext({
  token: null, setToken: () => {},
  user: null, setUser: () => {},
  showLinearProgress: false, setShowLinearProgress: () => {},
  cacheApp: {enums: {}}, setCacheApp: () => {},
  funcionario: null, setFuncionario: () => {},
  filtrosAgendaConsultorio: null, setFiltrosAgendaConsultorio: () => {},
});

export default StoreContext;
