import React from 'react';
import useStorage from 'src/utils/useStorage';
import Context from './Context';
import { getDataAtual } from 'src/utils/dataHora';

const StoreProvider = ({ children }) => {
  const [token, setToken] = useStorage('token');
  const [user, setUser] = useStorage('user');
  const [showLinearProgress, setShowLinearProgress] = useStorage('showLinearProgress');
  const [cacheApp, setCacheApp] = useStorage('cacheApp');
  const [funcionario, setFuncionario] = React.useState({});
  const [filtrosAgendaConsultorio, setFiltrosAgendaConsultorio] = React.useState({
    situacao: "", 
    dataAgenda: getDataAtual(), 
    periodo: new Date().getHours() < 12 ? "MA" : "TA", 
    agendadosParaMim: 0     
  });

  return (
    <Context.Provider
      value={{
        token, setToken,
        user, setUser,
        showLinearProgress, setShowLinearProgress,
        cacheApp, setCacheApp,
        funcionario, setFuncionario,
        filtrosAgendaConsultorio, setFiltrosAgendaConsultorio,
      }}
    >
      {children}
    </Context.Provider>
  )
}


export default StoreProvider;
